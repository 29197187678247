@import "src/styles/common.scss";

.launch-date {
    display: flex;
    color: var(--theme-socials-background);
}

.launch-numbers-days {
    margin-left: 15px;
    width: 50px;
}

.launch-numbers {
    margin-left: 10px;
    width: 100px;
}

.launch {
    position: fixed;
    height: 100px;
    width: 130%;
    bottom: 0%;
    background-color: var(--theme-navbar-background);
    z-index: 999;
    left: -15%;
    filter: drop-shadow(0px 0px 15px var(--theme-sticker-background));
}

.launch-title {
    color: var(--theme-link-color);
    letter-spacing: 5px;
    display: flex;
    position: absolute;
    font-size: 72px;
    text-align: center;
    justify-content: center;
    width: 100%;
    white-space: pre;
    height: 100%;
    align-items: center;
    background: #fa2d92;
    font-family: "Gotham Rounded", sans-serif;
}

@media screen and (max-width: $p-mobile), screen and (max-width: $tablet) {
    .launch-numbers-days {
        width: 20px;
    }
    .launch-numbers {
        margin-left: 5px;
        width: 30px;
    }
    .launch {
        height: 70px;
    }

    .launch-title {
        font-size: 12px;
    }
}
