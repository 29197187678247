$p-mobile: 575.98px;
$l-mobile: 767.98px;
$tablet: 991.98px;
$desktop: 1199.98px;
$l-desktop: 1399.98px;

$main-color: #e45689;
$secondary-color: #ff2c91;
$ternary-color: #060641;
$quaternary-color: #4fc3f7;

.container {
    margin: auto;
    min-height: calc(100vh - 120px);
}
@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

h2 {
    display: flex;
    font-family: "Gotham Rounded", sans-serif;
    width: 100%;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 40px;
    text-align: left;
}

ul {
    padding: 0;
}

.component-container {
    display: flex;
    align-items: center;
    padding: 150px 0px 100px 0px;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width: $desktop) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    h2 {
        text-align: center;
        justify-content: center;
    }
}
@media (max-width: $p-mobile) {
    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }
}

@media (max-width: $tablet) {
    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }
}
