@import "src/styles/common.scss";

.navbar-container {
    display: flex;
    justify-content: center;
    position: fixed;
    margin-bottom: 75px;
    height: 50px;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--theme-navbar-background);
    transition: 1s;
    width: 100%;
    z-index: 1000;
    -webkit-animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.navbar-without-background {
    display: flex;
    height: 100%;
    z-index: 1000;
    padding-left: 30px;
    padding-right: 30px;
    transition: 1s;
    width: 100%;
}

.navbar-with-background {
    display: flex;
    height: 100%;
    z-index: 1000;
    background-color: $main-color;
    padding-left: 30px;
    padding-right: 30px;
    transition: 1s;
    width: 100%;
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.navbar {
    margin: auto;
    //min-height: calc(100vh - 700px);
}
@media (min-width: 768px) {
    .navbar {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .navbar {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .navbar {
        width: 1170px;
    }
}

.logo {
    height: 80%;
    display: flex;
    align-items: center;
    transition: 1s;
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.casette {
    cursor: pointer;
}

.links-container {
    display: flex;
    flex: 100%;
    align-items: center;
}

.links {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 22px;
    align-items: center;
}

.link {
    cursor: pointer;
}

.active-link {
    font-family: "Gotham Rounded", sans-serif;
    color: var(--theme-sticker-background);
    padding: 3px 10px 3px 10px;
    justify-content: center;
    transition: 1s;
    border: 3px solid var(--theme-sticker-background) !important;
    filter: drop-shadow(0px 0px 15px var(--theme-sticker-background));
    color: white;
}

.active-scroll-link {
    color: red;
}

.hamburger-menu {
    display: none;
}

.hamburger-menu-dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 50px;
    min-width: 100%;
    justify-content: space-evenly;
    font-size: 30px;
    height: 50%;
    z-index: 1000;
    background-color: black;
    -webkit-animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.language-menu-dropdown {
    position: relative;
    display: flex;
    filter: drop-shadow(0px 0px 15px var(--theme-sticker-background));
    img {
        margin: 2px 0px;
    }
    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__content {
        display: none;
        position: absolute;
        z-index: 1;
    }
}

.language-menu-dropdown:hover .language-menu-dropdown__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.responsive-links-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
}

.roadmap {
    &-title {
        position: relative;
        background-color: black;
    }

    &-background {
        background: linear-gradient(
            45deg,
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(63, 218, 216, 1) 40%,
            rgba(47, 201, 226, 1) 50%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%
        );
        background-size: 1000% 1000%;
        animation: rainbow 5s linear infinite;
        filter: blur(2px);
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        z-index: -1;
        position: absolute;
    }

    &-active {
        filter: drop-shadow(0px 0px 0px transparent);
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.dark-light-theme {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-around;
    background: #6703fe;
    cursor: pointer;
    border: 5px double;
    margin-right: 30px;
}

.audio-element {
    cursor: pointer;
}

.telegram {
    fill: white;
    border: 5px double;
    height: 36px;
    margin-right: 30px;
    cursor: pointer;
}

.flag-wrapper {
    fill: white;
    height: 30px;
    cursor: pointer;
}

@media screen and (max-width: $p-mobile), screen and (max-width: $tablet) {
    .navbar-without-background {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-container,
    .navbar-with-background {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 30px;
    }

    .links {
        display: none;
    }

    .dark-light-theme {
        display: none;
    }

    .dark-light-theme-mobile-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dark-light-theme-mobile {
        display: flex;
        align-items: center;
        width: 35%;
        justify-content: space-evenly;
        background: #6703fe;
        cursor: pointer;
        border: 5px double;
    }

    .theme-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .hamburger-menu {
        display: block;
        justify-content: flex-end;
        font-size: 30px;
        transition: 2s;
        cursor: pointer;
    }

    .social-mobile-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
        img {
            width: 40px;
        }
    }

    .telegram {
        display: none;
    }

    .language-menu-dropdown {
        display: none;
    }

    .logo {
        margin-left: 20px;
    }

    .roadmap-title {
        padding: 7px;
    }
}
