@import "src/styles/common.scss";

.stickers {
    padding-top: 100px;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    grid-column-gap: 30px;
    &__col {
        margin-bottom: 30px;
        display: flex;
        flex: 45%;
        justify-content: center;
        height: 320px;
        border: 1px solid #272775;
        align-items: center;
        &:nth-child(1) {
            border-top: 10px solid $main-color;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0) 40%, rgba(228, 86, 137, 0.2) 100%);
        }
        &:nth-child(2) {
            border-top: 10px solid $quaternary-color;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0) 40%, rgba(79, 195, 247, 0.15) 100%);
        }
        &:nth-child(3) {
            border-top: 10px solid $quaternary-color;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0) 40%, rgba(79, 195, 247, 0.15) 100%);
        }
        &:nth-child(4) {
            border-top: 10px solid $main-color;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0) 40%, rgba(228, 86, 137, 0.2) 100%);
        }
        &:nth-child(5) {
            border-top: 10px solid $main-color;
            background: linear-gradient(0deg, rgba(2, 0, 36, 0) 40%, rgba(228, 86, 137, 0.2) 100%);
        }
    }

    &__image {
        display: flex;
        max-width: 270px;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
        &-wrapper {
            display: flex;
            flex: 44%;
            justify-content: flex-start;
            &--large {
                display: flex;
                flex: 20%;
            }
        }
    }

    &__description {
        &-wrapper {
            display: flex;
            flex-direction: column;
            flex: 40%;
            font-size: 20px;
            img {
                max-width: 200px;
            }
            &--large {
                display: flex;
                flex: 40%;
                flex-direction: column;
                font-size: 18px;
                margin: 0px 20px;
                height: 100%;
                justify-content: space-evenly;
                img {
                    max-width: 100%;
                    width: 370px;
                    margin-top: 25px;
                }
            }
        }
        &-logo {
            margin-bottom: 15px;
        }
        &-text {
            display: flex;
            flex-direction: column;
            span {
                margin: 3px 0px;
            }
            &--large {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 100%;
            }
        }
    }
    &__coming {
        &-wrapper {
            &--large {
                display: flex;
                flex: 20%;
                flex-direction: column;
                font-size: 50px;
                margin: 20px 0px;
                transform: rotate(-20deg);
                text-align: center;
                font-family: "Gotham Rounded", sans-serif;
                color: $main-color;
            }
        }
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
}

@media (max-width: $desktop) {
    .stickers {
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;

        &__col {
            flex-direction: column;
        }

        &__image-wrapper {
            justify-content: center;
            margin-bottom: 10px;
            img {
            }
        }

        &__description-wrapper {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}
