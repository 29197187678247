@import "src/styles/common.scss";

.header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.row {
    width: 100%;
    .column {
        &:nth-child(1) {
            justify-content: flex-start;
        }
        &:nth-child(2) {
            justify-content: flex-end;
        }
    }
}

.squirt-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url("./drops.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.moon {
    position: absolute;
    max-width: 230px;
    right: 20%;
    top: 5%;
    filter: drop-shadow(0px 0px 100px var(--theme-sticker-background));
    -webkit-animation: heartbeat 2s ease-in-out infinite both;
    animation: heartbeat 2s ease-in-out infinite both;
}

.rocket {
    position: absolute;
    height: 75px;
    right: 34%;
    top: 22%;
    z-index: 1;
    animation: circle 6s linear infinite;
}

@keyframes circle {
    0% {
        transform: rotate(0deg) translate(-165px) rotate(45deg);
    }
    100% {
        transform: rotate(360deg) translate(-165px) rotate(45deg);
    }
}

.banana-0 {
    position: absolute;
    z-index: 1;
    height: 120px;
    right: 23%;
    bottom: 34%;
    animation: float-banana-1 6s infinite;
}

.saber {
    position: absolute;
    z-index: 1;
    height: 120px;
    right: 15%;
    bottom: 10%;
    animation: float-saber 6s infinite;
}

.logo {
    max-width: 400px;
}

@keyframes float-ape {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes float-banana-1 {
    0% {
        transform: translatey(0px) translateX(0px) rotateZ(-30deg);
    }
    50% {
        transform: translatey(30px) translateX(40px) rotateZ(-50deg);
    }
    100% {
        transform: translatey(0px) translateX(0px) rotateZ(-30deg);
    }
}

@keyframes float-saber {
    0% {
        transform: translatey(0px) rotate(60deg);
    }
    50% {
        transform: translatey(-30px) rotate(30deg);
    }
    100% {
        transform: translatey(0px) rotate(60deg);
    }
}

.banana-1 {
    position: absolute;
    z-index: 1;
    height: 145px;
    right: 19%;
    top: 4%;
    animation: float-banana-2 6s infinite;
}

@keyframes float-banana-2 {
    0% {
        transform: translatey(0px) rotate(210deg) rotateZ(20deg);
    }
    50% {
        transform: translatey(-30px) rotate(180deg) rotateZ(10deg);
    }
    100% {
        transform: translatey(0px) rotate(210deg) rotateZ(20deg);
    }
}

.banana-2 {
    position: absolute;
    z-index: 1;
    height: 140px;
    left: 40%;
    bottom: 70%;
    animation: float-banana-3 6s infinite;
}

@keyframes float-banana-3 {
    0% {
        transform: translatey(0px) rotate(40deg) rotateZ(100deg);
    }
    50% {
        transform: translatey(-30px) rotate(60deg) rotateZ(110deg);
    }
    100% {
        transform: translatey(0px) rotate(40deg) rotateZ(100deg);
    }
}

.header-welcome-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    a {
        margin: 0px 20px;
    }
}

.welcome-message {
    font-family: "Gotham Rounded", sans-serif;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}

.welcome-description {
    font-family: "Gotham Rounded Book", sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.socials-wrapper {
    display: flex;
    height: 30px;
    width: 100%;
    justify-content: space-evenly;
    img {
        height: 30px;
    }

    a {
        margin: 0px;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
    }
}

.token-contract {
    font-family: "Gotham Rounded", sans-serif;
    color: $main-color;
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
}

.token-address {
    font-family: "Gotham Rounded Book", sans-serif;
    font-size: 20px;
    color: white;
}

.ape-in-button {
    font-family: "Gotham Rounded", sans-serif;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    font-size: 20px;
    letter-spacing: 1px;
    height: 45px;
    font-size: 18px;
    font-weight: bold;
    background-color: var(--theme-button-background);
    color: var(--theme-button-color);
    flex-wrap: wrap;
    color: white;
    text-align: center;
    &:hover {
        background-color: #28a745;
        filter: drop-shadow(0px 0px 5px #28a745);
    }
    transition: 1s;
}

.banana-closed {
    height: 74px;
    left: -14px;
    display: flex;
    position: absolute;
}

.button-wrapper {
    display: flex;
    margin: 30px 0px;
    width: 100%;
    a {
        margin: 0px;
        &:nth-child(1) {
            margin-right: 5px;
            padding: 0px 10px;
            white-space: nowrap;
        }
        &:nth-child(2) {
            height: 45px;
            width: 100%;
            margin-left: 5px;
            white-space: nowrap;
            background-color: $quaternary-color;
            &:hover {
                background-color: #28a745;
                filter: drop-shadow(0px 0px 5px #28a745);
            }
        }
    }
}

.banana-opened {
    height: 74px;
    left: -46px;
    display: flex;
    position: absolute;
}

.button-slippage {
    font-size: 9px;
}

.squirt-lady {
    animation: float 6s ease-in-out infinite;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@media screen and (max-width: $desktop) {
    .token-address {
        font-size: 10px;
    }

    .squirt-wrapper {
        margin-top: 50px;
    }

    .moon {
        left: 30%;
        height: 270px;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
    }

    .rocket {
        position: absolute;
        height: 70px;
        top: 36%;
        right: 120px;
    }

    @keyframes circle {
        0% {
            transform: rotate(0deg) translate(-140px) rotate(45deg);
        }
        100% {
            transform: rotate(360deg) translate(-140px) rotate(45deg);
        }
    }

    .banana-0 {
        left: 60%;
        bottom: 10%;
    }

    .banana-1,
    .banana-2 {
        display: none;
    }

    .component-container {
        height: 100%;
        padding-top: 100px;
    }

    .button-wrapper {
        flex-direction: column;
        a {
            margin: 20px 0px;
            &:nth-child(1) {
                margin-right: 0;
            }

            &:nth-child(2) {
                margin-left: 0;
            }
        }
    }

    .row {
        .column {
            &:nth-child(1) {
                justify-content: center;
            }
            &:nth-child(2) {
                justify-content: center;
            }
        }
    }

    .squirt-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
