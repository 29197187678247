@import "src/styles/common.scss";

.tokenomics {
    height: 500px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__background {
        height: 550px;
        justify-content: center;
        background-color: #040434;
        width: calc(100vw - 20px);
        position: absolute;
        left: 0;
        right: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        &-title {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-direction: column;
            span {
                color: $main-color;
                font-size: 24px;
            }
        }
    }

    &__diagram {
        position: relative;
        max-width: 350px;
    }

    &__bottom {
        &-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__sticker {
        max-width: 360px;
        height: 125px;
        width: 100%;
        position: relative;
        margin: 20px;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        background-color: $main-color;
        &:nth-child(1) {
            background-color: #1f1f60;
            border-left: 10px solid $main-color;
        }
        &:nth-child(2) {
            background-color: #1f1f60;
            border-left: 10px solid #4fc3f7;
        }
        &:nth-child(3) {
            background-color: #1f1f60;
            border-left: 10px solid #ba6ed7;
        }
        &:nth-child(4) {
            background-color: #1f1f60;
            border-left: 10px solid #6cd2ab;
        }
        &-text {
            margin: auto 20px;
        }
        &-title {
            font-family: "Gotham Rounded", sans-serif;
            font-size: 18px;
            margin: 0px;
        }
        &-description {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            span {
                margin: 2px 0px;
            }
        }
        &-wrapper {
            display: flex;
            position: relative;
            width: 72%;
            flex-wrap: wrap;
        }
    }
}
@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
}

@media (max-width: $desktop) {
    .tokenomics {
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;
        align-items: center;

        &__wrapper-title {
            text-align: center;
        }
    
        &__bottom-wrapper {
            flex-direction: column;
            align-content: center;
        }
        
        &__sticker-wrapper {
            width: 100%;
            align-content: center;
            justify-content: center;
        }
    
        &__background {
            display: none;
        }
    
        &__sticker {
            margin: 20px 0px;
        }

        &__diagram {
            margin: auto;
        }
    }
}
