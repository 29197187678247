@import "src/styles/common.scss";

$button-bg: $main-color;
$cum-color: white;
$button-text-color: #fff;

.button {
    font-family: "Gotham Rounded", sans-serif;
    display: flex;
    position: relative;
    font-size: 16px;
    height: 30px;
    color: white;
    align-items: center;
    border: none;
    cursor: pointer;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    border: 3px solid transparent;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    margin: 0px 20px;

    &:focus {
        outline: 0;
    }

    &:active,
    &:hover {
        transform: scale(0.9);
        background-color: white;
        color: $secondary-color;
        transition: 1s;
    }

    &--animate {
        &:before,
        &:after {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            left: -20%;
            z-index: -1000;
            transition: all ease-in-out 0.5s;
            background-repeat: no-repeat;
        }

        &:before {
            display: flex;
            top: -75%;
            left: 0%;
            background-image: radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 30%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 15%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%);
            background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
            animation: topBubbles ease-in-out 1s forwards;
        }

        &:after {
            display: block;
            bottom: -75%;
            left: 0%;
            background-image: radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 15%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%),
                radial-gradient(circle, $cum-color 20%, transparent 20%);
            background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
            animation: bottomBubbles ease-in-out 1s forwards;
        }

        &:active {
            transform: scale(0.9);
            box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
        }
    }

    &--active {
        font-family: "Gotham Rounded", sans-serif;
        color: var(--theme-sticker-background);
        padding: 3px 10px 3px 10px;
        justify-content: center;
        transition: 1s;
        padding: 0px 20px;
        color: $secondary-color;
        background-color: white;
    }
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}
