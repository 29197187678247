@import "src/styles/common.scss";

.info {
    height: 280px;
    display: flex;
    width: 100%;
    justify-content: center;
    img {
        margin-left: 10px;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
    }
    &__col {
        &:nth-child(1) {
            flex: 65%;
            flex-wrap: wrap;
        }
        &:nth-child(2) {
            flex: 35%;
            margin-left: 10px;
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: $main-color;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $secondary-color;
            }
        }
        flex: 100%;
        display: flex;
        &-sticker1 {
            display: flex;
            align-items: center;
            flex: 45%;
            height: 50%;
            background: #1b1b5d;
            margin-right: 5px;
            img {
                max-width: 150px;
            }
        }
        &-sticker2 {
            display: flex;
            align-items: center;
            flex: 45%;
            height: 50%;
            background: #1b1b5d;
            margin-left: 5px;
            img {
                max-width: 150px;
            }
        }
        &-sticker3 {
            display: flex;
            align-items: center;
            flex: 100%;
            background: #443280;
            height: calc(50% - 10px);
            margin-top: 10px;

            img {
                max-width: 200px;
            }
            &-text {
                display: flex;
                flex-direction: column;
                margin-left: 50px;
                &--description {
                    font-size: 20px;
                }
            }

            &-techrate {
                cursor: pointer;
            }
        }
    }

    &__twitter {
        width: 100%;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-family: "Gotham Rounded", sans-serif;
        &--description {
            color: $main-color;
            font-size: 40px;
        }
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@media (max-width: $p-mobile) {
    .info {
        margin-top: 50px;
        flex-direction: column;
        height: 100%;
        font-size: 24px;

        img {
            width: 100%;
        }

        &__col-sticker1 {
            flex-direction: column;
            flex: 100%;
            margin: 20px 0px;
        }

        &__col-sticker2 {
            flex-direction: column;
            flex: 100%;
            margin: 20px 0px;
        }

        &__col-sticker3 {
            flex-direction: column;
            margin: 20px 0px;
            &-text {
                margin: 0px 10px;
                text-align: center;
                &--description {
                    font-size: 20px;
                }
            }
        }

        &__text-wrapper {
            &--description {
                font-size: 40px;
            }
        }

        &__col {
            &:nth-child(2) {
                margin: 20px 0px;
            }
        }
    }
}

@media (max-width: $tablet) {
}

@media (max-width: $desktop) {
    .info {
        margin-top: 50px;
        flex-direction: column;
        height: 100%;
        font-size: 24px;

        img {
            width: 100%;
        }

        &__col-sticker1 {
            flex-direction: column;
            flex: 100%;
            margin: 20px 0px;
        }

        &__col-sticker2 {
            flex-direction: column;
            flex: 100%;
            margin: 20px 0px;
        }

        &__col-sticker3 {
            flex-direction: column;
            margin: 20px 0px;
            &-text {
                margin: 0px 10px;
                text-align: center;
                &--description {
                    font-size: 20px;
                }
            }
        }

        &__text-wrapper {
            &--description {
                font-size: 40px;
            }
        }

        &__col {
            &:nth-child(2) {
                margin: 20px 0px;
            }
        }
    }
}
