@import "src/styles/common.scss";

.balance {
    &__container {
        font-family: "Gotham Rounded", sans-serif;
        position: fixed;
        left: 0;
        top: 30%;
        z-index: 100;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 220px;
        color: var(--theme-button-color);
        background-color: var(--theme-sticker-background);
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        filter: drop-shadow(0px 0px 15px var(--theme-sticker-background));
        &:hover {
            background-color: #28a745;
            filter: drop-shadow(0px 0px 15px #28a745);
        }
        transition: 1s;
    }

    &__container {
        &--open-wrapper {
            position: relative;
            display: flex;
            height: 100%;
            width: 100%;
        }

        &--open {
            font-family: "Gotham Rounded", sans-serif;
            position: fixed;
            left: 0;
            top: 30%;
            background-color: var(--theme-socials-background);
            z-index: 100;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 200px;
            color: var(--theme-button-color);
            background-color: var(--theme-sticker-background);
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            font-size: 16px;
            filter: drop-shadow(0px 0px 15px var(--theme-sticker-background));
            overflow: hidden;
            transition: 1s;
        }
    }
    &__text {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-orientation: upright;
        margin-left: 10px;
        span {
            margin: 0px;
        }

        &--open {
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            right: 0;
            width: 50px;
            font-weight: bold;
            writing-mode: vertical-lr;
            text-orientation: upright;
            position: absolute;
            background-color: #ff007e;
        }
    }

    &__block {
        font-family: "Gotham Rounded Bold", sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100px;
        justify-content: center;
        -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

        span {
            margin: 5px;
        }
        &--sub-title {
            font-family: "Gotham Rounded", sans-serif;
        }
        &--sub-text {
            font-family: "Gotham Rounded Book", sans-serif;
        }
    }

    &__connect {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-orientation: upright;
        margin-right: 10px;
        span {
            margin: 0px;
        }
    }
}

span {
    margin: 5px;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: $tablet) {
    .balance {
        &__container {
            display: none;
        }
    }
}
